.App {
  /* text-align: center; */
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb !important;
}

.kalenis-loading{
  background-color:rgba(236, 241, 247, 0.5);
  /* background-color:red; */
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height:100%;
  z-index:1000;
  
  
}

.kalenis-loading-relative{
  position:relative;
  display:flex;
  flex-grow: 1;
  background-color:rgba(236, 241, 247, 0.5) !important;
  z-index:9999;
  width:100%;
  height:100%;
  
}

.kalenis-spinner{
  position: absolute;
  top: 50%;
  left: 50%;
  animation: kalenis-spinner-anim infinite 1s linear;
  
}



.doc-table{
  border-collapse: collapse;
  border-spacing: 0;
 
  
  /* width: 100%; */
  /* border: 1px solid #ddd; */
}

.doc-main-titles{
  color: #203b60
}

.doc-middle-titles{
  color: #203b60
}

.doc-table-th td {
  color:white !important;
  background-color:#203b60;
  font-weight: 700;
}

.doc-table-tr {
  text-align: center;
  padding: 5px;
  /* border-bottom: 1px solid grey; */
  border: 1px solid #ddd;
  color: #203b60;
  font-family: 'Open Sans', sans-serif !important;
  background-color:white;
  text-align: left;
  word-break:keep-all;
  /* color:red; */
}

.doc-table-td {
  padding:10px !important;
  border: 1px solid #ddd;
  
}

table tr:nth-child(2n) {
  background: #f2f2f2;
 
}

.dependencies-block{
  color:#203b60;
  border: 1px solid #203b60;
  /* padding:5px; */
  text-align: justify;
  

  max-width: 25%;
  /* margin: 'auto'; */
  
}

.dependencies-block p {
  color:#203b60;
  margin-left:30px;
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.select-field-item {
  /* color: #203b60 !important; */
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif !important;
  /* font-weight: 500 !important; */

}
.select-field-item:hover{
  /* color:red !important; */
  color: #203b60 !important;
  font-weight:800 !important;
}
